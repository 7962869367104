import i18n from "i18next";

export function translate(item, caseType) {
  var translation = i18n.t(item.replace(".", ":"));

  if (translation === item || translation === item.replace(".", ":")) {
    translation = translation.split(".").pop();
  }

  if (translation) {
    if (caseType === "upper") {
      translation = translation.toUpperCase();
    } else if (caseType === "lower") {
      translation = translation.toLowerCase();
    } else if (caseType === "title") {
      translation = toTitleCase(translation);
    }
  }

  return translation;
}

function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}
