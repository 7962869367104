import {
  createSpinner,
  hideSpinner,
  showSpinner,
} from "@syncfusion/ej2-popups";
import { translate } from "./translate";

function create(id, label) {
  createSpinner({
    target: document.getElementById(id),
    label: (label ? label : translate("global.actionInProgress"))
  });
}

export function showLoading(id, label) {
  create(id, label);
  showSpinner(document.getElementById(id));
}

export function hideLoading(id) {
  hideSpinner(document.getElementById(id));
}
