import {
  ApolloClient,
  ApolloLink,
  DefaultOptions,
  InMemoryCache
} from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
// Constants
import { AUTH_TOKEN } from "./constants";

// For test use
var graphqlUri = `${process.env.REACT_APP_API_URL}`;

const uploadLink = createUploadLink({ uri: graphqlUri });

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: "Bearer " + localStorage.getItem(AUTH_TOKEN) || null,
    },
  }));

  return forward(operation);
});

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {    
    fetchPolicy: "network-only",
    errorPolicy: "all",
  },
};

const client = new ApolloClient({
  link: ApolloLink.from([authMiddleware, uploadLink]),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
});

export default client;
