export const AUTH_TOKEN = "sable-auth-token";
export const REFRESH_TOKEN = "sable-refresh-token";

export const LOGIN_AS_ACTIVATED = "sable-login-as-activated";
export const LOGIN_AS_AUTH_TOKEN = "sable-original-auth-token";
export const LOGIN_AS_REFRESH_TOKEN = "sable-original-refresh-token";

export const USER_ABBREVIATION = "sable-user-abbreviation";
export const USER_ROLE = "sable-user-role";
export const ACCOUNT_ID = "sable-user-account-id";

export const SETTINGS_ID = 1;

export const ROLES = {
  User: "user",
  Admin: "admin",
  SysAdmin: "sysadmin",
  Support: "support",
  Supplier: "supplier",
  Driver: "driver",
};

export const TYPE_OF_THEME_DEFAULT = "expa";
export const TYPE_OF_THEME_OCS = "ocs";
export const TYPE_OF_THEME_EXPRESS_ALLIANCE = "expa";